import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'

import 'vuetify/dist/vuetify.min.css'

import * as VueGoogleMaps from 'vue2-google-maps'
import store from "./store"
import router from "./router"

Vue.use(VueGoogleMaps, {
  load: {
    libraries: ['geometry','drawing'],
    key: 'AIzaSyC7wwe9JXEM1ljP7QqwuC8FbK_Vih8OGxQ'
  }
})

Vue.config.productionTip = false

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
