import Vue from 'vue'
import Vuex from 'vuex'
//import {compile} from "vue-template-compiler";

/*import geolocation from './geolocation'
import storage from './storage'
import map from './map'
import plant from './plant'
import survey from './survey'
import type from './type'
import user from './user'
*/

import MapObject from "@/store/MapObject";

Vue.use(Vuex)

export default new Vuex.Store({
    modules:{mapStore:MapObject},
    state:{
        gpsPosition: {coords:{latitude:46.635, longitude:14.311}},
        lat:46.635,
        lng:14.311,
        watchID: null,
        userdata: {username:null}
    },
    mutations:{
        setCenter(state, GPSObject){
            if('coords' in GPSObject) {
                state.gpsPosition = GPSObject
                state.lat = GPSObject.coords.latitude
                state.lng = GPSObject.coords.longitude
            }
            else {
                state.lat = GPSObject.latitude
                state.lng = GPSObject.longitude
            }
            console.log("setCenter with:")
            console.log(GPSObject)
        },
        toggleTracking(state){
            console.log("Toggle track. Actual:"+state.watchID)
            if(navigator && 'geolocation' in navigator) {
                if(!state.watchID) {
                    state.watchID = navigator.geolocation.watchPosition(position => {
                        this.commit('setCenter', position);
                        //Track in DB
                        //must copy geoobject to geht a stringrepresentation
                        let coords= {
                            accuracy: position.coords.accuracy,
                                altitude: position.coords.altitude,
                                altitudeAccuracy: position.coords.altitudeAccuracy,
                                heading: position.coords.heading,
                                latitude: position.coords.latitude,
                                longitude: position.coords.longitude,
                                speed: position.coords.speed,
                        }
                        let formdata = JSON.stringify({
                            username: state.userdata.username,
                            timestamp: position.timestamp,
                            gpsobject: coords})
                        fetch("/index.php/records/gpstrack",
                            {
                                credentials:"include",
                                method:"POST",
                                body: formdata
                            })
                        ////

                    }, errormsg => console.log(errormsg),
                        {enableHighAccuracy:true})
                }
                else {
                    navigator.geolocation.clearWatch(state.watchID)
                    state.watchID = null
                }
            }
            else
                alert('No GPS Support')
        },
        setUserdata(state, data){
            state.userdata=data
        }
    },
    getters:{
     accuracy: state =>{
         if(!state.watchID)
             return 'off'
         else
            return parseInt(state.gpsPosition.coords.accuracy)
     }
    }
/*    modules: {
        geolocation,
        storage,
        map,
        plant,
        survey,
        type,
        user,
    }*/
})
