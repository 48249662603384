<!--Component for Select Field-->
<template>
    <v-select v-model="model" :items="items" multiple :label="label">
        <template v-slot:prepend-item>
            <v-list-item
                    ripple
                    @click="model.length ? model=[] : model=items"
            >
                <v-list-item-action>
                    <v-icon :color="model.length > 0 ? 'indigo darken-4' : ''">
                        {{ model.length ? 'mdi-close-box' :'mdi-checkbox-blank-outline'  }}
                    </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>
                        Alle
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
        </template>
        <template v-slot:selection="{ item, index }">
            <span v-if="model.length === 1">{{ item }}</span>
            <span v-if="index === 1">
                  {{model.length==items.length?'Alle':model.length}} ausgewählt
                  </span>
        </template>
    </v-select>
</template>

<script>
    export default {
        name: "WDselect",
        props:['value','items','label'],
        watch:{
          model:function(){
             this.$emit('input', this.model)
          }
        },
        data:function(){
            return {
                model: []
            }
        }
    }
</script>

<style scoped>

</style>