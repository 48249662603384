<template>
  <GmapMap
      ref="mapRef"
      :center=mapcenter
      :zoom=mapzoom
      :mapTypeControl=true
      :options='{
                  styles:[
                  {"featureType": "poi",
            "stylers": [
            {
            "visibility": "off"
            }
            ]
            }
            ]}'
      @zoom_changed="mapzoom=$event"
      @click="$emit('click',$event)"
      @dragend="update_center"
      map-type-id="roadmap"
      style="width:100%; height:100%"
  >
    <template v-if="editableObject">
      <gmap-polygon v-for="(polygon,index) in editableObject.polypaths.machine_clean" :key="'clean_'+index"
                    :paths="polygon" :draggable=true :editable="true"
                    :options="{strokeColor: '#CD3333',fillColor: '#CD3333'}"
                    @paths_changed="updateEdited($event,'machine_clean', index)"
                    @rightclick="editableObject.polypaths.machine_clean.splice(index,1)">
      </gmap-polygon>
      <gmap-polygon v-for="(polygon,index) in editableObject.polypaths.machine_strow" :key="'strow_'+index"
                    :paths="!polygon?[]:polygon" :draggable=true :editable="true"
                    :options="{strokeColor: '#FE9A2E',fillColor: '#FE9A2E'}"
                    @paths_changed="updateEdited($event,'machine_strow', index )"
                    @rightclick="editableObject.polypaths.machine_strow.splice(index,1)">
      </gmap-polygon>
      <gmap-polygon v-for="(polygon,index) in editableObject.polypaths.manual" :key="'manual_'+index"
                    :paths="!polygon?[]:polygon" :draggable=true :editable="true"
                    :options="{strokeColor: '#006400',fillColor: '#006400'}"
                    @paths_changed="updateEdited($event,'manual',index)"
                    @rightclick="editableObject.polypaths.manual.splice(index,1)">
      </gmap-polygon>
      <gmap-polygon v-for="(polygon,index) in editableObject.polypaths.sidewalk" :key="'sidewalk_'+index"
                    :paths="!polygon?[]:polygon" :draggable=true :editable="true"
                    :options="{strokeColor: '#4876FF',fillColor: '#4876FF'}"
                    @paths_changed="updateEdited($event,'sidewalk', index)"
                    @rightclick="editableObject.polypaths.sidewalk.splice(index,1)">
      </gmap-polygon>
      <gmap-polygon v-for="(polygon,index) in editableObject.polypaths.snowfield" :key="'snow_'+index"
                    :paths="!polygon?[]:polygon" :draggable=true :editable="true"
                    :options="{strokeColor: '#000000',fillColor: '#FFFFFF', fillOpacity:'0.8'}"
                    @paths_changed="updateEdited($event,'snowfield', index)"
                    @rightclick="editableObject.polypaths.snowfield.splice(index,1)">
      </gmap-polygon>
    </template>
    <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen"
                      @closeclick="infoWinOpen=false">
    </gmap-info-window>
    <!--template #visible>
    <gmap-drawing-manager
        :rectangle-options="rectangleOptions"
        :circle-options="circleOptions"
        :polyline-options="polylineOptions"
        :shapes="shapes"
    ></gmap-drawing-manager>
    </template-->
    <!-- shows all possible markers -->
    <gmap-marker
        :key="m.ID+'_'+m.type"
        v-for="(m) in visibleObjects"
        :position="{lat:m.lat*1,lng:m.lng*1}"
        :clickable="true"
        :draggable=m.draggable
        @dblclick="debounce(()=>startEditObject(m))"
        @mouseover="toggleInfoWindow(m)"
        @mouseout="infoWinOpen=false"
        @dragend="updateMarkerPosition($event.latLng)"
        :icon="generatesymbol(m.type,typeof selectedContractors[m.type]=='undefined'?0:selectedContractors[m.type].indexOf(m[m.type]))">
    </gmap-marker>
    <gmap-marker v-if="showCenter"
        :position="mapcenter"
        :icon="generatesymbol('tractor')">
    </gmap-marker>
  </GmapMap>
</template>

<script>
import {generatesymbol} from "../_helpers/symbolgenerator"

export default {
  name: "MapComponent",
  props: ['selectedContractors','draggable','showCenter'],
  data: function () {
    return {
      mapzoom: 15,
      infoOptions: {
        content: '',
        //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      infoWindowPos: null,
      infoWinOpen: false,
      translate: this.$store.state.mapStore.translate
    }
  },
  computed: {
    visibleObjects() {
      return this.$store.state.mapStore.matchingObjects
    },
    editableObject:{
      get(){
        return this.$store.state.mapStore.editableObject
        },
      set(newVal){
          this.$store.commit('setEditableObject', {obj :newVal, modifydraggable: this.draggable})
      }
    },
    mapcenter: {
      get(){
        return {lat: this.$store.state.lat, lng: this.$store.state.lng}
      }
    }
  },
  methods: {
    generatesymbol,
    debounce: function(modifyObject) {
      console.log("modfunction")
      console.log(modifyObject)
      if(typeof modifyObject==='undefined')
        return
      if (this.timeout)
        clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        if(typeof modifyObject == 'function')
          modifyObject()
        else
          Object.keys(modifyObject).forEach((key)=>this[key]=modifyObject[key])
      }, 200); // delay
    },
    toggleInfoWindow: function (marker) {
      this.infoWindowPos = {lat: marker.lat * 1, lng: marker.lng * 1};
//TODO: Change to acitvities array ?
      this.infoOptions.content = `<div><strong>${marker.company}-${marker.oid}</strong>
                <br>Räumung: ${marker['machine_clean']}<br>Streuung: ${marker['machine_strow']}
                <br>Händisch: ${marker['manual']}<br>Gehsteig: ${marker['sidewalk']}</div>`;

      this.infoWinOpen = true;
    },
    startEditObject: function (marker) {
      console.log(marker)
      //set Position to center Map to Marker
      this.$store.commit('setCenter', {latitude: marker.lat * 1, longitude: marker.lng * 1})
      this.mapzoom = 19
      //this.mod(marker.ID)
      let temp = this.visibleObjects.find((wdObj) => marker.ID == wdObj.ID)
      //add polypaths if not exists
      console.log('POLY')
      console.log("polypath= " + temp.polypaths)
      if (!temp.polypaths)
        temp.polypaths =
            {
              manual: [],
              machine_clean: [],
              machine_strow: [],
              sidewalk: [],
              snowfield: [],
            }
      this.editableObject = temp
    },
    updateEdited(mvcArray, polytype, index) {
    console.log("updateEdited")
    console.log(mvcArray)
    let paths = [];
    for (let i = 0; i < mvcArray.getLength(); i++) {
      let path = [];
      for (let j = 0; j < mvcArray.getAt(i).getLength(); j++) {
        let point = mvcArray.getAt(i).getAt(j);
        path.push({lat: point.lat(), lng: point.lng()});
      }
      paths.push(path);
    }
    if (index >= 0)
      this.editableObject.polypaths[polytype][index] = paths
    else
      this.editableObject.polypaths[polytype] = paths
  },
    updateMarkerPosition: function(latlng){
      //not more neededconsole.log("updateMarker"+ID)
      console.log("updateMarker"+latlng)
      if(this.editableObject && (this.editableObject.draggable || !this.editableObject.oid)){
        //let temp = this.editableObject
        console.log(latlng)
        this.editableObject.lat = latlng.lat()
        this.editableObject.lng = latlng.lng()
/*        if(!this.editableObject.trigger)
        {
          console.log('add trigger')
          this.editableObject.trigger = true}
        else
          delete this.editableObject.trigger*/
//        console.log("set editable OBJ")
        //this.editableObject = temp
//        this.$store.commit('updateEditableObject', this.editableObject)
//        this.$emit('markerDragEnd',{ID:ID,latlng:latlng})
      }},
    update_center: function(){
        let center = this.$refs.mapRef.$mapObject.getCenter()
        this.$store.commit('setCenter',{latitude:center.lat(),longitude:center.lng()})
        this.updateMarkerPosition(center)
    }
  }
}
</script>

<style scoped>

</style>