<template>
  <div>
    <div v-if="files!=null">
      <v-btn
          v-for="(item, index) in files" v-bind:key="index"
          outlined
          block
          target="_blank"
          :href="`/data/${oid}/${item['name']}.${item['extension']}`">
              Datei {{ index+1 }} - {{item['extension']}}
      </v-btn>
    </div>
    <h3 v-else>Keine Daten vorhanden</h3>
  </div>
</template>

<script>
export default {
  name: "LinkList",
  props: {
    oid: null
  },
  data: () => ({
    filelist: null
  }),
  computed: {
    files: function () {
      if (!!this.filelist && this.filelist.length)
        return this.filelist
      else
        return null
    }
  },
  watch: {
    oid: {
      handler: function (value) {
        console.log("lade daten")
        fetch(process.env.VUE_APP_BASE + '/objectdata.php/' + value)
            .then(response => {
              if (!response.ok) {
                throw Error(response.statusText)
              }
              return response.json()
            })
            .then(data => this.filelist = data)
            .catch(error => this.filelist = error)
      }
      ,
      immediate: true
    }
  },
}
</script>

<style scoped>

</style>