import {gmapApi} from 'vue2-google-maps'

const colorpalette = [
    "#994f72",
    "#8a6ded",
    "#43d0b0",
    "#9fbb32",
    "#5257cf",
    "#ae782e",
    "#4b9b2f",
    "#9f3daa",
    "#47c581",
    "#d4409d",
    "#5dc551",
    "#e03f78",
    "#3c782c",
    "#bd74e3",
    "#75902f",
    "#e26ed3",
    "#8ac075",
    "#416ed5",
    "#cdaa38",
    "#7654b1",
    "#e18e31",
    "#7c88e7",
    "#e46b37",
    "#52b5e3",
    "#ce3e29",
    "#3bbcc3",
    "#d4404f",
    "#63bfa1",
    "#b4417c",
    "#519c60",
    "#925898",
    "#bbb767",
    "#3b69b1",
    "#ae782e",
    "#558ecc",
    "#a74d27",
    "#ae9de4",
    "#5b6114",
    "#d98ac4",
    "#327243",
    "#e17f93",
    "#379577",
    "#a44750",
    "#206e54",
    "#e07e70",
    "#6562a0",
    "#8b7b2d",
    "#dd9d6c",
    "#5a632b",
    "#905f31",
    "#869353"]

function generatesymbol(type, colorindex) {
    console.log('gensymbol')
    let googleapi = gmapApi();
    //tour asd = new asd.maps.Point(24,44)
    let symbolanchor = new googleapi.maps.Point(24,44)

    if(colorindex >= colorpalette.length)
        console.warn("colorpalette too small")
    var symbol = {
        fillColor: (colorindex)?colorpalette[colorindex]:(type=='tractor')?'#5ba02e':'#ae782e',
        fillOpacity: 0.8,
        scale: 1,
        strokeColor: '#333',
        strokeWeight: 2,
        label:'C',
        anchor: symbolanchor
//        labelOrigin: new google.maps.Point(0, -15)
    };

    //check if objekt is done
    //if (typeof this.$store.state.userdata.checkedOBJ != 'undefined' && this.$store.state.userdata.checkedOBJ.some((element)=>element.ID == markerID)) {
    if (type == 'done') {
        symbol.scale= 0.7
        symbol.fillColor = 'green'
    }
    if (type == 'alert') {
        symbol.fillColor = 'orange'
    }

    if (type == "manual")
        symbol["path"] = 'M24 3.252C15.64 3.252 8.8 10.092 8.8 18.452 9.256 21.34 9.332 24.304 11.004 26.356 11.384 26.964 11.764 27.496 12.22 28.028L24 44.748 35.324 28.636C36.16 27.648 36.844 26.66 37.452 25.596L37.528 25.444 37.528 25.444C38.592 23.316 39.2 20.96 39.2 18.452 39.2 10.092 32.36 3.252 24 3.252zM29.776 6.596C30.08 6.596 30.308 6.748 30.46 6.9L34.032 10.472C34.26 10.7 34.412 11.004 34.336 11.384 34.26 11.688 34.032 11.992 33.652 12.068L31.144 13.056C30.688 13.284 30.08 13.132 29.7 12.752L29.624 12.752C29.624 12.752 29.472 12.524 29.244 12.752L24 18.072C23.62 18.376 23.848 18.604 24 18.68 25.292 20.276 25.14 22.556 23.696 24.076L20.504 27.268C20.352 27.496 19.896 27.648 19.516 27.268L13.74 21.492C13.36 21.188 13.512 20.732 13.74 20.58L16.932 17.312C18.376 15.868 20.732 15.792 22.328 17.008 22.404 17.084 22.632 17.312 22.936 17.008L28.256 11.688C28.408 11.46 28.256 11.308 28.256 11.308L28.18 11.232C27.8 10.852 27.648 10.244 27.876 9.788L28.864 7.28C28.94 6.9 29.244 6.672 29.548 6.672 29.624 6.596 29.7 6.596 29.776 6.596zM30.004 8.496C29.852 8.496 29.852 8.648 29.852 8.648L29.244 10.168C29.244 10.168 29.244 10.244 29.32 10.32L30.612 11.612C30.688 11.688 30.84 11.688 30.84 11.688L32.36 11.08C32.36 11.08 32.512 11.004 32.36 10.852L30.08 8.572C30.08 8.572 30.004 8.572 30.004 8.496z'
    else if (type == "machine_clean")
        symbol["path"] = 'M24 3.252C15.64 3.252 8.8 10.092 8.8 18.452 9.256 21.34 9.332 24.304 11.004 26.356 11.384 26.964 11.764 27.496 12.22 28.028L24 44.748 35.324 28.636C36.16 27.648 36.844 26.66 37.452 25.596L37.528 25.444 37.528 25.444C38.592 23.316 39.2 20.96 39.2 18.452 39.2 10.092 32.36 3.252 24 3.252zM26.204 8.42 26.204 8.42 33.272 8.42 33.272 14.348 33.272 14.348 33.272 17.616C33.804 18.376 34.108 19.364 34.108 20.352 34.108 23.012 31.98 25.216 29.32 25.216 26.584 25.216 24.456 23.088 24.456 20.428 22.632 20.428 20.884 20.428 19.212 20.428 19.744 20.96 20.048 21.644 20.048 22.404 20.048 23.924 18.756 25.216 17.16 25.216 15.64 25.216 14.348 23.924 14.348 22.404 14.348 21.644 14.576 20.96 15.108 20.428 14.728 20.428 14.348 20.428 13.968 20.428L13.132 15.488C13.132 15.108 12.98 14.576 14.044 14.424L24 14.424 24.836 9.332C25.14 8.952 25.52 8.572 26.204 8.42z'
    else if (type == "machine_strow")
        symbol["path"] = 'M24 3.304C15.661 3.304 8.838 10.127 8.838 18.466 9.293 21.347 9.369 24.303 11.036 26.35 11.416 26.957 11.795 27.487 12.249 28.018L24 44.696 35.296 28.624C36.13 27.639 36.812 26.653 37.418 25.592L37.494 25.44 37.494 25.44C38.556 23.318 39.162 20.968 39.162 18.466 39.162 10.127 32.339 3.304 24 3.304ZM20.968 7.852 23.621 7.852C25.592 7.852 26.274 7.852 26.35 7.852 26.578 8.004 26.578 8.08 26.578 8.914L26.578 9.748 27.866 11.036C29.307 12.477 29.383 12.553 29.458 13.008 29.458 13.159 29.458 13.841 29.458 15.054L29.458 16.95 30.292 16.95 31.126 16.95 31.126 14.372 31.126 11.795 31.884 11.112C32.642 10.354 32.642 10.354 32.794 10.354 33.097 10.354 33.325 10.657 33.173 10.885 33.173 10.961 32.87 11.264 32.567 11.567L31.96 12.174 31.96 16.267C31.96 18.769 31.96 20.437 31.96 20.589 31.884 21.195 31.354 21.877 30.747 22.181 30.368 22.332 30.141 22.408 29.458 22.408 28.094 22.408 27.184 22.408 24.986 22.408 25.061 22.256 25.061 22.181 25.061 22.029 25.061 20.134 23.469 18.542 21.574 18.542 19.679 18.542 18.087 20.134 18.087 22.029 18.087 22.181 18.163 22.256 18.163 22.408L17.935 22.408C16.874 22.408 16.495 22.256 15.964 21.726 15.661 21.422 15.509 21.119 15.433 20.74 15.282 20.361 15.282 17.329 15.433 17.177 15.509 16.95 15.585 16.95 16.798 16.95L17.859 16.95 17.859 14.979 17.859 12.932 17.935 12.704C18.087 12.477 18.238 12.325 19.451 11.112L20.74 9.748 20.74 8.914 20.74 8.08 20.892 7.928 20.968 7.852ZM21.574 8.686 21.574 9.065 21.574 9.444 23.697 9.444 25.744 9.444 25.744 9.065 25.744 8.686 23.697 8.686 21.574 8.686ZM21.574 19.148C23.166 19.148 24.455 20.437 24.455 22.029 24.379 22.56 24.227 23.09 24.076 23.394 23.924 23.697 23.773 23.848 23.469 24.076 21.65 25.365 18.997 24.682 18.693 22.029 18.693 20.437 19.982 19.148 21.574 19.148ZM21.574 19.679C20.361 19.679 19.3 20.664 19.3 21.953 19.3 23.242 20.361 24.303 21.574 24.303 22.863 24.303 23.924 23.242 23.924 21.953 23.924 20.664 22.863 19.679 21.574 19.679Z'
    else if (type == "sidewalk")
        symbol["path"] = 'M24 3.252C15.64 3.252 8.8 10.092 8.8 18.452 9.256 21.34 9.332 24.304 11.004 26.356 11.384 26.964 11.764 27.496 12.22 28.028L24 44.748 35.324 28.636C36.16 27.724 36.844 26.66 37.452 25.596L37.528 25.444 37.528 25.444C38.592 23.392 39.2 20.96 39.2 18.452 39.2 10.092 32.36 3.252 24 3.252ZM25.748 10.472C26.888 10.472 27.8 11.384 27.8 12.524 27.8 13.436 27.116 14.272 26.28 14.5 26.356 14.652 26.432 14.804 26.432 14.956 29.928 19.592 30.916 17.844 30.764 19.288 30.536 21.568 25.52 17.54 25.52 17.54L25.14 18.984C25.14 18.984 27.344 20.96 27.8 22.176L28.56 25.292C28.56 25.368 28.56 25.444 28.56 25.444L26.432 25.444 25.824 23.012 23.24 20.884 23.316 20.808 23.24 20.808 23.316 20.884 19.896 25.444 17.996 25.444C17.768 25.064 17.768 24.532 18.072 24.152L22.404 16.324C22.404 16.324 21.72 16.4 21.188 17.084 19.592 18.984 19.44 20.276 18.376 19.82 17.844 19.592 17.692 18.984 17.996 18.528L19.668 15.716C19.592 15.792 19.668 15.716 19.744 15.564L19.668 15.716C19.82 15.488 21.112 14.044 24.076 13.512 23.924 13.208 23.772 12.828 23.772 12.448 23.772 11.308 24.684 10.396 25.824 10.396Z'
    else if (type == "tour")
        symbol["path"] = "M24 4C16.268 4 10 10.268 10 18 10 28.5 24 44 24 44 24 44 38 28.5 38 18 38 10.268 31.732 4 24 4Z";
    else if(type == 'tractor')
        symbol["path"]= "m22.848 5.466-3.987 13.978h-5.286v-10.752h-2.285v10.662l-7.571.941 .627 6.989h-4.211v7.392h2.912v.493c0 3.718 3.046 6.675 6.675 6.675 3.584 0 6.675-2.912 6.675-6.675 0-1.299-.358-2.509-1.03-3.539h7.258c.538 5.824 5.466 10.17 11.155 10.17 6.003 0 11.2-4.928 11.2-11.2 0-5.018-3.27-9.139-7.661-10.618v-14.47h-14.47zm2.285 2.778h9.094v11.155h-.493c-1.344 0-2.643.269-3.853.717l-7.795-.627 3.046-11.2zm8.602 17.875c2.867 0 4.57 2.106 4.57 4.48s-1.702 4.48-4.57 4.48-4.57-2.106-4.57-4.48 1.702-4.48 4.57-4.48zm-24.058 5.824c1.971 0 3.45 1.523 3.45 3.226 0 1.747-1.434 3.226-3.45 3.226-1.971 0-3.45-1.523-3.45-3.226 0-1.747 1.434-3.226 3.45-3.226z"
    else if(type == 'done' || type == 'alert')
        symbol["path"]= "M20.906 28.032l13.126-13.218-2.812-2.812-10.312 10.406-4.126-4.218-2.812 2.812zM24 4.032q5.812 0 9.89 4.078t4.078 9.89q0 2.906-1.454 6.656t-3.516 7.032-4.078 6.14-3.422 4.546l-1.5 1.594q-.562-.656-1.5-1.734t-3.376-4.312-4.266-6.282-3.328-6.89-1.5-6.75q0-5.812 4.078-9.89t9.89-4.078z"

        //symbol["path"] = "M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z";
    else
        return null;



    return symbol;
}

export {generatesymbol}