<template>
  <v-app>
    <!--v-navigation-drawer app clipped absolute temporary v-model="drawer">
      <v-list dense>
        <v-list-tile >
          <v-list-tile-action>
            <v-icon>home</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Home</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile >
          <v-list-tile-action>
            <v-icon>contact_mail</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Contact</v-list-tile-title>
          </v-list-Btile-content>
        </v-list-tile>
      </v-list>
    </v-navigation-drawer-->
    <v-app-bar color="green" app dark clipped-left>
      <v-toolbar-title>
        <template v-if="role=='admin'"><!-- && ['xs','sm','md'].includes($vuetify.breakpoint.name)"-->
                <v-menu
                    dark
                    bottom
                    left
                    offset-y
                    transition="slide-y-transition"
                    id="menu"
                    v-if="role=='admin'"
                >
                  <template v-slot:activator="{on}">
                    <v-btn
                        fab
                        small
                        color="gray"
                        v-on="on"
                    >
                      <v-icon>mdi-menu</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                        link
                        @click="$store.commit('toggleTracking');"
                    >
                      <v-icon>mdi-crosshairs-gps</v-icon>
                      GPS Tracking de/aktivieren
                    </v-list-item>
                    <v-list-item
                        link
                        @click="addNewObject">
                      <v-icon>mdi-map-marker-plus</v-icon>
                      Objekt hinzufügen
                    </v-list-item>
                    <v-list-item
                        link
                        @click="infowindow='.'">
                      <v-icon>mdi-file-upload-outline</v-icon>
                      XLSX hochladen
                    </v-list-item>
                    <v-list-item
                        link
                        @click="showUserMgmt=true"
                    >
                      <v-icon>mdi-account</v-icon>
                      Benutzer verwalten
                    </v-list-item>
                    <v-list-item
                        link
                        @click="showObjSearch=true;editableObject=false"
                    >
                      <v-icon>mdi-magnify</v-icon>
                      Objektsuche
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-btn
                    fab
                    small
                    color="gray"
                    @click="$store.commit('toggleTracking')"
                >
                <span>
                <v-icon>mdi-crosshairs-gps</v-icon><br>
                {{ $store.getters.accuracy }}
                </span>
                </v-btn>

                <v-btn
                    fab
                    small
                    color="gray"
                    @click="logout"
                >
                  <v-icon>mdi-logout</v-icon><br>
                </v-btn>
        </template>
        WDControl - {{this.$store.state.userdata.username}}
      </v-toolbar-title>
      <template v-if="role!='admin'">
        <v-spacer></v-spacer>
        <v-btn
          fab
          smaller
          color="gray"
          @click="$store.commit('toggleTracking')"
        >
                <span>
                <v-icon>mdi-crosshairs-gps</v-icon><br>
                {{ $store.getters.accuracy }}
                </span>
        </v-btn>
        <v-btn
            fab
            smaller
            class="ml-5 ml-sm-10"
            color="gray"
            @click="logout"
        >
          <v-icon>mdi-logout</v-icon><br>
        </v-btn>
      </template>

      <template v-slot:extension v-if="role=='admin'">
        <v-row justify="space-around" justify-sm="center" align="end">
          <!-- war ursprünglich ein 2tes Menü für Desktopberieb v-col-- v-if="['lg','xl'].includes($vuetify.breakpoint.name)==true" cols="12" sm="2" xl="1"  class="d-none d-md-inline">
            <v-row  style="gap:10px;flex-wrap: nowrap" no-gutters>
              <v-menu
                  dark
                  bottom
                  left
                  offset-y
                  transition="slide-y-transition"
                  id="menu"
                  v-if="role=='admin'"
              >
                <template v-slot:activator="{on}">
                  <v-btn
                      fab
                      larger
                      color="gray"
                      style="position:relative;top:-10px"
                      v-on="on"
                  >
                    <v-icon>mdi-menu</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                      link
                      @click="$store.commit('toggleTracking');"
                  >
                    <v-icon>mdi-crosshairs-gps</v-icon>
                    GPS Tracking de/aktivieren
                  </v-list-item>
                  <v-list-item
                      link
                      @click="addNewObject">
                    <v-icon>mdi-map-marker-plus</v-icon>
                    Objekt hinzufügen
                  </v-list-item>
                  <v-list-item
                      link
                      @click="infowindow='.'">
                    <v-icon>mdi-file-upload-outline</v-icon>
                    XLSX hochladen
                  </v-list-item>
                  <v-list-item
                      link
                      @click="showUserMgmt=true"
                  >
                    <v-icon>mdi-account</v-icon>
                    Benutzer verwalten
                  </v-list-item>
                  <v-list-item
                      link
                      @click="showUserMgmt=true"
                  >
                    <v-icon>mdi-magnify</v-icon>
                    Objektsuche
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn
                  fab
                  larger
                  color="gray"
                  style="position:relative;top:-10px"
                  @click="$store.commit('toggleTracking')"
              >
                <span>
                <v-icon>mdi-crosshairs-gps</v-icon><br>
                {{ $store.getters.accuracy }}
                </span>
              </v-btn>
              <v-btn
                  fab
                  larger
                  color="gray"
                  style="position:relative;top:-10px"
                  @click="logout"
              >
                <v-icon>mdi-logout</v-icon><br>
              </v-btn>
            </v-row>
          </v-col-->
          <v-col v-for="(value,type) in contractors" :key="type">
            <WDselect v-model=selectedContractors[type] :items="contractors[type]" :label="translate[type]">
            </WDselect>
          </v-col>
        </v-row>
        <!--/div-->
        <!--/v-tab>
      </v-tabs-->
      </template>
    </v-app-bar>
    <v-navigation-drawer v-if="editableObject" clipped app :width="this.$vuetify.breakpoint.name=='xs'?'100%':'400px'" mobile-breakpoint="0">
      <v-tabs grow v-model="objecttabs">
        <v-tab>Informationen</v-tab>
        <v-tab>Dokumente</v-tab>
      </v-tabs>
      <v-tabs-items v-model="objecttabs">
        <v-tab-item>
          <v-card class="fill-height" >
            <v-toolbar>
              <v-toolbar-title>{{ editableObject.company }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                  icon
                  @click="editableObject = false;polytype=false;"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <template v-slot:extension>
                <v-btn-toggle @change="addPolygon" v-model=polytype class="flex-grow-1" v-if="role=='admin'">
                  <v-tooltip bottom>
                    <template v-slot:activator="{on}">
                      <v-btn
                          icon
                          value='machine_clean'
                          color="#CD3333"
                          v-on="on"
                          class="flex-grow-1"
                      >
                        <v-icon>mdi-tractor</v-icon>
                      </v-btn>
                    </template>
                    <span>maschinell räumen</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{on}">
                      <v-btn
                          icon
                          value='machine_strow'
                          color="#FE9A2E"
                          v-on="on"
                          class="flex-grow-1"
                      >
                        <v-icon>mdi-shower-head</v-icon>
                      </v-btn>
                    </template>
                    <span>maschinell streuen</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{on}">
                      <v-btn
                          icon
                          value='manual'
                          color="#006400"
                          v-on="on"
                          class="flex-grow-1"
                      >
                        <v-icon>mdi-shovel</v-icon>
                      </v-btn>
                    </template>
                    <span>händisch räumen</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{on}">
                      <v-btn
                          icon
                          value='sidewalk'
                          color="#4876FF"
                          v-on="on"
                          class="flex-grow-1"
                      >
                        <v-icon>mdi-walk</v-icon>
                      </v-btn>
                    </template>
                    <span>Gehsteig</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{on}">
                      <v-btn
                          icon
                          value='snowfield'
                          color="#FFFFFF"
                          v-on="on"
                          class="flex-grow-1"
                      >
                        <v-icon>mdi-snowflake</v-icon>
                      </v-btn>
                    </template>
                    <span>Schneeablage</span>
                  </v-tooltip>
                </v-btn-toggle>
                <template v-else>
                  <v-btn
                      color="green lighten-4"
                    @click="markObjDone(editableObject.oid)"
                    width="50%"
                  >
                  Erledigt
                    <v-icon
                        right
                    >
                      mdi-check-bold
                    </v-icon>
                  </v-btn>
                  <v-btn
                      color="blue lighten-3"
                      width="50%"
                    @click="navigate"
                  >
                  Navigation
                    <v-icon
                        right
                    >
                      mdi-navigation-variant
                    </v-icon>
                  </v-btn>
                </template>
              </template>
            </v-toolbar>
            <!--v-card-title class="text-h5 grey lighten-2">
              {{editableObject.firma}}
              <v-spacer></v-spacer>
              <v-btn
                  icon
                  @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title-->
            <v-carousel
            hide-delimiter-background
            :show-arrows="false"
            height="auto"
            >

              <v-carousel-item>
                <v-card-text>
                  <v-form>
                    <v-container>
                      <v-row align="center">
                        <v-col cols="3">
                          <strong>OID</strong>
                        </v-col>
                        <v-col>
                          <v-text-field v-if="!!editableObject.address"
                                        v-model="editableObject.oid"
                                        readonly>
                          </v-text-field>
                          <v-text-field v-else
                                        v-model="editableObject.oid"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row align="center">
                        <v-col cols="3">
                          <strong>Firma</strong>
                        </v-col>
                        <v-col>
                          <v-text-field
                              v-model="editableObject.company"
                              readonly>
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row align="center">
                        <v-col cols="3">
                          <strong>Strasse</strong>
                        </v-col>
                        <v-col>
                          <v-text-field
                              v-model="editableObject.address"
                              readonly>
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row align="center">
                        <v-col cols="3">
                          <strong>PLZ</strong>
                        </v-col>
                        <v-col>
                          <v-text-field
                              v-model="editableObject.postcode"
                              readonly>
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row align="center">
                        <v-col cols="3">
                          <strong>Ort</strong>
                        </v-col>
                        <v-col>
                          <v-text-field
                              v-model="editableObject.city"
                              readonly>
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <div v-if="role=='admin'">
                        <v-row align="center">
                          <v-col cols="3">
                            <strong>maschinelle Räumung</strong>
                          </v-col>
                          <v-col>
                            <v-select :readonly="!editableObject.valid" :items="contractors['machine_clean']"
                                      v-model="editableObject['machine_clean']">
                            </v-select>
                          </v-col>
                        </v-row>
                        <v-row align="center">
                          <v-col cols="3">
                            <strong>maschinelle Streuung</strong>
                          </v-col>
                          <v-col>
                            <v-select :readonly="!editableObject.valid"  :items="contractors['machine_strow']"
                                      v-model="editableObject['machine_strow']">
                            </v-select>
                          </v-col>
                        </v-row>
                        <v-row align="center">
                          <v-col cols="3">
                            <strong>händische R/S</strong>
                          </v-col>
                          <v-col>
                            <v-select :readonly="!editableObject.valid"  :items="contractors['manual']"
                                      v-model="editableObject['manual']">
                            </v-select>
                          </v-col>
                        </v-row>
                        <v-row align="center">
                          <v-col cols="3">
                            <strong>Gehsteig</strong>
                          </v-col>
                          <v-col>
                            <v-select :readonly="!editableObject.valid"  :items="contractors['sidewalk']"
                                      v-model="editableObject['sidewalk']">
                            </v-select>
                          </v-col>
                        </v-row>
                        <v-row align="center">
                          <v-col cols="3">
                            <strong>Status</strong>
                          </v-col>
                          <v-col>
                            <v-select
                                :items="statusfields"
                                :messages="editableObject['status']"
                                v-model="editableObject['status']"
                                :value-comparator="(a,b)=>a=='Fehler'?1:a==b"
                            >
                            </v-select>
                          </v-col>
                        </v-row>
                      </div>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-carousel-item>

              <v-carousel-item>
                <v-card-text class="no_underlines">
                  <v-form>
                    <v-container>
                      <div v-if="editableObject.leader">
                        <v-row align="center" no-gutters>
                          <v-clo>
                            <strong>Einsatzleitung</strong>
                          </v-clo>
                        </v-row>
                        <v-row align="center" no-gutters>
                          <v-col>
                            {{editableObject.leader}}
                          </v-col>
                          <v-col v-if="typeof(editableObject.leader_number) == 'string'">
                            <a :href="'tel:'+editableObject.leader_number.replace('/','')">{{editableObject.leader_number}}</a>
                          </v-col>
                        </v-row>
                      </div>
                      <div v-if="editableObject.machine_clean">
                        <v-row align="center" no-gutters>
                          <v-col><strong>Räumen:</strong></v-col>
                        </v-row>
                        <v-row align="center" no-gutters>
                          <v-col>
                            {{editableObject.machine_clean}}
                          </v-col>
                          <v-col v-if="typeof(editableObject.machine_clean_number) == 'string'">
                            <a :href="'tel:'+editableObject.machine_clean_number.replace('/','')">{{editableObject.machine_clean_number}}</a>
                          </v-col>
                        </v-row>
                      </div>
                      <div v-if="editableObject.machine_strow">
                        <v-row align="center" no-gutters>
                          <strong>Streuen:</strong>
                        </v-row>
                        <v-row align="center" no-gutters>
                          <v-col>
                            {{editableObject.machine_strow}}
                          </v-col>
                          <v-col v-if="typeof(editableObject.machine_strow_number) == 'string'">
                            <a :href="'tel:'+editableObject.machine_strow_number.replace('/','')">{{editableObject.machine_strow_number}}</a>
                          </v-col>
                        </v-row>
                      </div>
                      <div v-if="editableObject.manual">
                        <v-row align="center" no-gutters>
                          <strong>Händisch:</strong>
                        </v-row>
                        <v-row align="center" no-gutters>
                          <v-col>
                            {{editableObject.manual}}
                          </v-col>
                          <v-col v-if="typeof(editableObject.manual_number) == 'string'">
                            <a :href="'tel:'+editableObject.manual_number.replace('/','')">{{editableObject.manual_number}}</a>
                          </v-col>
                        </v-row>
                      </div>
                      <div v-if="editableObject.sidewalk">
                        <v-row align="center" no-gutters>
                          <strong>Gehsteig:</strong>
                        </v-row>
                        <v-row align="center" no-gutters>
                          <v-col>
                            {{editableObject.sidewalk}}
                          </v-col>
                          <v-col v-if="typeof(editableObject.sidewalk_number) == 'string'">
                            <a :href="'tel:'+editableObject.sidewalk_number.replace('/','')">{{editableObject.sidewalk_number}}</a>
                          </v-col>
                        </v-row>
                      </div>
                    </v-container>
                  </v-form>
                </v-card-text>

              </v-carousel-item>

              <v-carousel-item  >
                <v-card-text class="no_underlines">
                  <v-form>
                    <v-container>
                      <v-row align="center" no-gutters>
                        <v-col>
                          <strong>Einsatzzeiten</strong>
                        </v-col>
                      </v-row>
                      <v-row align="center" no-gutters>
                        <v-col cols="3">Wochentage:</v-col><v-col>{{ editableObject.workdays }}</v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col cols="3">Uhrzeit:</v-col><v-col>{{ editableObject.worktime }}</v-col>
                      </v-row>
                      <v-row align="center" >
                        <v-col>
                          <strong>Einsatzinfo</strong>
                          <v-textarea
                              rows="1"
                              readonly
                              auto-grow
                              dense
                              :value=editableObject.objectinfo
                          >
                          </v-textarea>
                        </v-col>
                      </v-row>
                      <v-row align="center" no-gutters>
                        <v-col>
                          <strong>Besonderheiten</strong>
                          <v-textarea
                              readonly
                              auto-grow
                              dense
                              rows="1"
                              :value=editableObject.specialinfo
                          >
                          </v-textarea>
                        </v-col>
                      </v-row>
                      <v-row align="center" no-gutters>
                        <v-col>
                          <strong>Zeitraum</strong>
                          <v-text-field
                              readonly
                              dense
                              :value=editableObject.period
                          >
                          </v-text-field>
                        </v-col>
                        <v-col>
                          <strong>Schlüsselnummer</strong>
                          <v-text-field
                              readonly
                              flat
                              dense
                              :value=editableObject.keynumber
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row align="center" no-gutters>
                        <v-col>
                          <strong>Untersch. Kunde</strong>
                          <v-simple-checkbox
                              :value=editableObject.customersignature
                              readonly
                          ></v-simple-checkbox>
                        </v-col>
                        <v-col>
                          <strong>TWK</strong>
                          <v-simple-checkbox
                              :value=editableObject.twk
                              readonly
                          ></v-simple-checkbox>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-carousel-item>

            </v-carousel>

            <v-divider></v-divider>

            <v-card-actions v-if="role=='admin'">
              <v-btn
                  color="primary"
                  text
                  @click="saveObject"
              >
                Speichern
              </v-btn>
              <loadingBtn :status=loading></loadingBtn>
              <v-spacer></v-spacer>
              <v-btn
                  color="primary"
                  text
                  @click="console.log('trigger'+editableObject.trigger);editableObject = false;polytype=null;"
              >
                Schließen
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card class="fill-height" >
            <v-toolbar extended>
              <v-toolbar-title>{{ editableObject.company }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                  icon
                  @click="editableObject = false;polytype=false;"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <!--v-toolbar>
              <v-toolbar-title>{{ editableObject.firma }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn-toggle @change="addPolygon" v-model=polytype>
                <v-tooltip bottom>
                  <template v-slot:activator="{on}">
                    <v-btn
                        icon
                        value='machine_clean'
                        color="#006400"
                        v-on="on"
                    >
                      <v-icon>mdi-tractor</v-icon>
                    </v-btn>
                  </template>
                  <span>maschinell räumen</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{on}">
                    <v-btn
                        icon
                        value='machine_strow'
                        color="#4876FF"
                        v-on="on"
                    >
                      <v-icon>mdi-shower-head</v-icon>
                    </v-btn>
                  </template>
                  <span>maschinell streuen</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{on}">
                    <v-btn
                        icon
                        value='manual'
                        color="#CD3333"
                        v-on="on"
                    >
                      <v-icon>mdi-shovel</v-icon>
                    </v-btn>
                  </template>
                  <span>händisch räumen</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{on}">
                    <v-btn
                        icon
                        value='sidewalk'
                        color="#FFD700"
                        v-on="on"
                    >
                      <v-icon>mdi-walk</v-icon>
                    </v-btn>
                  </template>
                  <span>Gehsteig</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{on}">
                    <v-btn
                        icon
                        value='snowfield'
                        color="#9E9E9E"
                        v-on="on"
                    >
                      <v-icon>mdi-snowflake</v-icon>
                    </v-btn>
                  </template>
                  <span>Schneeablage</span>
                </v-tooltip>
              </v-btn-toggle>
              <v-btn
                  icon
                  @click="editableObject = false;polytype=false;"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </--v-toolbar>
            <v-card-title class="text-h5 grey lighten-2">
              {{editableObject.firma}}
              <v-spacer></v-spacer>
              <v-btn
                  icon
                  @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title-->

            <v-card-text>
              <LinkList :oid="editableObject.oid"></LinkList>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions v-if="role=='admin'">
              <v-btn
                  color="primary"
                  text
                  @click="saveObject"
              >
                Speichern
              </v-btn>
              <loadingBtn :status=loading></loadingBtn>
              <v-spacer></v-spacer>
              <v-btn
                  color="primary"
                  text
                  @click="console.log('trigger'+editableObject.trigger);editableObject = false;polytype=null;"
              >
                Schließen
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid fill-height class="pa-0" style="align-content: start">
        <!--v-icon
            large
            color="green darken-2"
        >
          mdi-domain
        </v-icon-->
        <!--div style="width:30px;background-color: red;position: absolute;z-index: 10">

        </div-->

        <MapComponent v-if="!showObjSearch" @markerDrag-End="this.editableObject.lat=$event['latlng'].lat()"
                      :selected-contractors="selectedContractors" @click="addPolyPoint($event)"
                      :draggable="this.$store.state.userdata.role=='admin'"
                      :showCenter="$store.getters.accuracy=='off'?false:true">
        </MapComponent>
        <v-list v-if="showObjSearch" style="width: calc(100% - 30px); margin: 0px 15px">
          <v-text-field
            v-model="searchCompany"
            append-icon="mdi-magnify"
            label="Suche"
            single-line
            hide-details
          ></v-text-field>

          <v-list-item-group v-model="vlist_item" @change="setEditableObjectByID">
            <v-list-item  v-for="object in filteredCompanies" :key="object.ID" :value="object.ID">
              <v-list-item-content>
                <v-list-item-title class="text-wrap">{{ object.company }} - {{ object.address }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
          </v-list>

      </v-container>

      <!--v-dialog
          :value="editableObject"
          content-class="editWindow"
          hide-overlay
          persistent
          width="500"
          :retain-focus="false"
          no-click-animation
      >

        <v-card v-if="editableObject">
          <v-toolbar @mousedown="startmove" >
            <v-toolbar-title>{{ editableObject.firma }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn-toggle @change="addPolygon" v-model=polytype>
              <v-tooltip bottom>
                <template v-slot:activator="{on}">
                  <v-btn
                      icon
                      value='machine_clean'
                      color="#006400"
                      v-on="on"
                  >
                    <v-icon>mdi-tractor</v-icon>
                  </v-btn>
                </template>
                <span>maschinell räumen</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{on}">
                  <v-btn
                      icon
                      value='machine_strow'
                      color="#4876FF"
                      v-on="on"
                  >
                    <v-icon>mdi-shower-head</v-icon>
                  </v-btn>
                </template>
                <span>maschinell streuen</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{on}">
                  <v-btn
                      icon
                      value='manual'
                      color="#CD3333"
                      v-on="on"
                  >
                    <v-icon>mdi-shovel</v-icon>
                  </v-btn>
                </template>
                <span>händisch räumen</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{on}">
                  <v-btn
                      icon
                      value='sidewalk'
                      color="#FFD700"
                      v-on="on"
                  >
                    <v-icon>mdi-walk</v-icon>
                  </v-btn>
                </template>
                <span>Gehsteig</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{on}">
                  <v-btn
                      icon
                      value='snowfield'
                      color="#9E9E9E"
                      v-on="on"
                  >
                    <v-icon>mdi-snowflake</v-icon>
                  </v-btn>
                </template>
                <span>Schneeablage</span>
              </v-tooltip>
            </v-btn-toggle>
            <v-btn
                icon
                @click="editableObject = false;polytype=false;"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-container>
                <v-row align="center">
                  <v-col cols="3">
                    <strong>OID</strong>
                  </v-col>
                  <v-col>
                    <v-text-field v-if="!!editableObject.strasse"
                                  v-model="editableObject.oid"
                                  disabled>
                    </v-text-field>
                    <v-text-field v-else
                                  v-model="editableObject.oid"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col cols="3">
                    <strong>Firma</strong>
                  </v-col>
                  <v-col>
                    <v-text-field
                        v-model="editableObject.company"
                        disabled>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col cols="3">
                    <strong>Strasse</strong>
                  </v-col>
                  <v-col>
                    <v-text-field
                        v-model="editableObject.address"
                        disabled>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col cols="3">
                    <strong>PLZ</strong>
                  </v-col>
                  <v-col>
                    <v-text-field
                        v-model="editableObject.postcode"
                        disabled>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col cols="3">
                    <strong>Ort</strong>
                  </v-col>
                  <v-col>
                    <v-text-field
                        v-model="editableObject.city"
                        disabled>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col cols="3">
                    <strong>maschinelle Räumung</strong>
                  </v-col>
                  <v-col>
                    <v-select :disabled="!editableObject.valid" :items="contractors['machine_clean']"
                              v-model="editableObject['machine_clean']">
                    </v-select>
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col cols="3">
                    <strong>maschinelle Streuung</strong>
                  </v-col>
                  <v-col>
                    <v-select :disabled="!editableObject.valid"  :items="contractors['machine_strow']"
                              v-model="editableObject['machine_strow']">
                    </v-select>
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col cols="3">
                    <strong>händische R/S</strong>
                  </v-col>
                  <v-col>
                    <v-select :disabled="!editableObject.valid"  :items="contractors['manual']"
                              v-model="editableObject['manual']">
                    </v-select>
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col cols="3">
                    <strong>Gehsteig</strong>
                  </v-col>
                  <v-col>
                    <v-select :disabled="!editableObject.valid"  :items="contractors['sidewalk']"
                              v-model="editableObject['sidewalk']">
                    </v-select>
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col cols="3">
                    <strong>Status</strong>
                  </v-col>
                  <v-col>
                    <v-select
                        :items="statusfields"
                        :messages="editableObject['status']"
                        v-model="editableObject['status']"
                        :value-comparator="(a,b)=>a=='Fehler'?1:a==b"
                    >
                    </v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
                color="primary"
                text
                @click="saveObject"
            >
              Speichern
            </v-btn>
            <loadingBtn :status=loading></loadingBtn>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="console.log('trigger'+editableObject.trigger);editableObject = false;polytype=null;"
            >
              Schließen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog-->
      <v-dialog
          v-model=infowindow
          width="500px"
          persistent
      >
        <v-card>
          <v-card-title>
            <span>XLSX Upload</span>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <v-form v-if="!geolocateprogress" @submit.prevent="updateDB">
              <v-file-input
                  accept=".xlsx,.xlsm"
                  placeholder="Datei
                  auswahl"
                  name="xlsxfile"
              >
              </v-file-input>
              <input type="hidden" name="fileupload" />
              <v-btn type="submit" color="primary">Aktualisieren</v-btn>
            </v-form>
            <v-progress-linear
                v-if="geolocateprogress"
                v-model="geolocate_calculated"
                height="25"
            >
              <template v-slot:default>
                <strong>{{ geolocate_calculated }}%</strong>
              </template>
            </v-progress-linear>
            <div v-html="infowindow">
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn
                color="primary"
                text
                block
                @click="infowindow = false; geolocateprogress=0;$store.dispatch('loadAllObjects')"
            >
              Schließen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <UserProfiles @on="showUserMgmt=false" :dialog="showUserMgmt" v-if="role=='admin'"></UserProfiles>
      <v-dialog
          v-model="savewarning"
          width="500px"
          v-if="role=='admin'"
      >
        <v-card>
          <v-card-title>
            <span>Achtung</span>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            Der Marker wurde verschoben, aber der Status befindet sich immer noch auf Fehler.
          </v-card-text>
          <v-card-actions>
            <v-btn
                color="primary"
                text
                @click="saveObject(true)"
            >
              Okey
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="savewarning = false"
            >
              Abbrechen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog >
    </v-main>
    <v-footer color="green" padless class="justify-space-between">
      <span class="white--text text-caption">powered by Crellutions</span>
      <v-spacer></v-spacer>
      <span class="white--text text-caption">&copy; 2021</span>
    </v-footer>
  </v-app>
</template>
<script>


import WDselect from "@/components/wd-select";
import MapComponent from "@/components/MapComponent.";
import loadingBtn from "@/components/loadingBtn";
import LinkList from "@/components/LinkList";
import UserProfiles from "@/components/UserProfiles";

export default {
  name: 'MainPage',
  components: {UserProfiles, LinkList, MapComponent, WDselect, loadingBtn},
  data: () => ({
    vlist_item:"",
    searchCompany: "",
    showUserMgmt: false,
    showObjSearch: false,
    statusfields: ['Aktiv','Angebot','Archiviert',{text:'Fehler',value:'Fehler',disabled:true}],
    objecttabs:null,
    loading:'',
    mapcenter: {lat: 46.635, lng: 14.311},
    mapzoom: 15,
    drawer: false,
    drawerRight: true,
    right: false,
    left: false,
    savewarning: false,
    infowindow: false,
    geolocateprogress:0,
    polypaths: {
      manual: [],
      machine_clean: [],
      machine_strow: [],
      sidewalk: [],
    },
    polytype: null,

    toolbarPosition: 'TOP_CENTER',
    shapes: [],
    temp: -3,
    items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
    translate: {
      machine_clean: "maschinelle Räumung",
      machine_strow: "maschinelle Streuung",
      manual: "händisch Räumen/Streuen",
      sidewalk: "Gehsteig",
      status: "Status"
    },
    //wdObjects_all: [],
    wdObjects_mr: [],
    selectedContractors: {
      machine_clean: [],
      machine_strow: [],
      manual: [],
      sidewalk: [],
      state: []
    },
    wdObjects_ms: [],
    //refactor
    infoOptions: {
      content: '',
      //optional: offset infowindow so it visually sits nicely on top of our marker
      pixelOffset: {
        width: 0,
        height: -35
      }
    },
    //refactore
    infoWindowPos: null,
    //refactore
    infoWinOpen: false,
    typesbyObject: []
  }),
  props: {
    source: String
  },
  computed: {
    geolocate_calculated: function() {
      let status = this.geolocateprogress.split('/')

      return Math.floor(status[0]/status[1]*100)
    },
    distanceGPS: function(){
      let point1 = {
        lat:this.editableObject.lat,
        lng:this.editableObject.lng
      }
      let point2 = {
        lat:this.$store.state.lat,
        lng:this.$store.state.lng
      }
      var lat = [point1.lat, point2.lat]
      var lng = [point1.lng, point2.lng]
      var R = 6378137;
      var dLat = (lat[1]-lat[0]) * Math.PI / 180;
      var dLng = (lng[1]-lng[0]) * Math.PI / 180;
      var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
          Math.cos(lat[0] * Math.PI / 180 ) * Math.cos(lat[1] * Math.PI / 180 ) *
          Math.sin(dLng/2) * Math.sin(dLng/2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
      var d = R * c;
      return Math.round(d);
    },
    sortedCompanies: function() {
      // Sortiert die Objekte in wdObjects_all alphabetisch nach der 'company'-Eigenschaft.
      if(this.wdObjects_all){
      return this.wdObjects_all.slice().sort((a, b) => {
        return a.company.localeCompare(b.company);
      });}
      return false;
    },
    filteredCompanies() {
      if (!this.searchCompany.trim())
        return this.wdObjects_all;

      // Filtert und sortiert die Unternehmen basierend auf dem Suchbegriff.
      let searchterms = this.searchCompany.toLowerCase().split(' ').filter(t => t);

      return this.wdObjects_all
          .filter((obj) =>
              searchterms.every(term => obj.company.toLowerCase().includes(term) ||
                  obj.address.toLowerCase().includes(term))
          )
          .sort((a, b) => a.company.localeCompare(b.company));

    },
    wdObjects_all: function () {
      return this.$store.state.mapStore.allWDObjects
    },
    contractors: function () {
      if(this.$store.state.userdata.role=='admin')
        return this.$store.getters.all_contractors
      else
        return this.$store.getters.all_contractors_in_tours
    },
    editableObject: {
      get: function () {
        console.log("editable changed")
        let obj = this.$store.state.mapStore.editableObject
        //check if object has a valid state
        if(obj)
          obj.valid = this.statusfields.includes(obj.status)
        return obj
      },
      set: function (newVal) {
        console.log('object save commit')
        this.$store.commit('setEditableObject', {obj :newVal, modifydraggable: this.$store.state.userdata.role=='admin'})
      }
    },
//    matchingObject: function () {
//      return this.$store.getters.matchingObjects(this.selectedContractors)
//    },
    role:function (){
      return this.$store.state.userdata.role;
    },
    console: () => console
  },
  methods: {
    logout: function(){
      this.$router.push('/login');
      this.$store.commit('setUserdata', {});
      document.cookie="PHPSESSID=''"
    },
    navigate:function(){
        // If it's an iPhone..
        if ((navigator.platform.indexOf("iPhone") !== -1) || (navigator.platform.indexOf("iPod") !== -1)) 	{
          window.open('http://maps.apple.com/maps?q='+ this.editableObject.lat + ',' + this.editableObject.lng);
        }
        else if(navigator.platform.indexOf("Android") !== -1){
          window.open('geo:' + this.editableObject.lat + ',' + this.editableObject.lng);
        }
        else {
        window.open('http://maps.apple.com/maps?q='+ this.editableObject.lat + ',' + this.editableObject.lng);
      }

      },
    markObjDone:function(objID){
      let userdata =this.$store.state.userdata


      //we really need the timestamp to be a string? Strange
      let objData ={
          objID: objID,
          username: userdata.username,
          tour: this.editableObject.tour,
          timestamp: Date.now(),
          save: true
      }
/*
      if(typeof userdata.checkedOBJ == 'undefined')
        userdata.checkedOBJ = []
      if(!userdata.checkedOBJ.some(element => element.ID==objID)) {
        userdata.checkedOBJ.push(
            objData
        )
      }
*/
      //this.$store.commit("setUserdata", userdata)
      this.$store.commit("setObjectDone", objData)
    },
    setEditableObjectByID: function (ID) {
      console.log("list ID "+ID)
      let WDObj = this.$store.getters.getObjectById(ID)
      this.$store.commit('setCenter', {latitude: WDObj.lat * 1, longitude: WDObj.lng * 1})
      this.editableObject =WDObj
      this.showObjSearch= false
    },
    startmove: function (event) {
      const closestDialog = event.target.closest(".v-dialog.v-dialog--active")
      const mouseStartX = event.clientX
      const mouseStartY = event.clientY
      const dialogStartX = closestDialog.getBoundingClientRect().left
      const dialogStartY = closestDialog.getBoundingClientRect().top
      //const oldPosition = closestDialog.style.position
      //const oldTransition = closestDialog.style.transition

      closestDialog.style.transition = "none"
      closestDialog.style.position = "fixed"
      let movefunction = function (e) {
        closestDialog.style.top = dialogStartY + e.clientY - mouseStartY - 25 + "px";
        closestDialog.style.left = dialogStartX + e.clientX - mouseStartX - 20 + "px";
      }
      document.addEventListener("mousemove", movefunction)

      closestDialog.addEventListener("mouseup", () => {
        console.log("remove")
        //closestDialog.style.position = oldPosition
        //closestDialog.style.translate = oldTransition
        //closestDialog.style.top = dialogStartY
        //closestDialog.style.left = dialogStartX
        document.removeEventListener("mousemove", movefunction)
        /*console.log("start"+mouseStartY)
        console.log("mouse"+ e.clientY)
        console.log("dialog"+dialogStartY)
        console.log(dialogStartY + e.clientY - mouseStartY)*/
      })
    },
    addNewObject: function () {
      console.log(new Object)
      //this.mapcenter={lat:marker.lat*1,lng:marker.lng*1}
      //TODO get empty object from API
      // this.mapzoom=18
      let temp = new Object(); //this.matchingObject.find((wdObj)=>marker.ID == wdObj.ID)
      //add polypaths if not exists
      console.log("polypath=" + temp.polypaths == null)
      if (!temp.polypaths)
        temp.polypaths =
            {
              manual: [],
              machine_clean: [],
              machine_strow: [],
              sidewalk: [],
              snowfield: [],
            }
      temp.lat = this.$store.state.lat
      temp.lng = this.$store.state.lng
      temp.status = 'Ohne OID'
      this.editableObject = temp
    },
    updateEdited(mvcArray, polytype, index) {
      console.log("updateEdited")
      console.log(mvcArray)
      let paths = [];
      for (let i = 0; i < mvcArray.getLength(); i++) {
        let path = [];
        for (let j = 0; j < mvcArray.getAt(i).getLength(); j++) {
          let point = mvcArray.getAt(i).getAt(j);
          path.push({lat: point.lat(), lng: point.lng()});
        }
        paths.push(path);
      }
      if (index >= 0)
        this.editableObject.polypaths[polytype][index] = paths
      else
        this.editableObject.polypaths[polytype] = paths
    },
    addPolyPoint(event) {
      console.log(event)
      if (!this.polytype || !this.editableObject)
        return

      if (this.editableObject.polypaths[this.polytype].length == 0)//typeof this.polypaths[this.polytype] === 'undefined')// &&
        this.editableObject.polypaths[this.polytype].push([event.latLng])
      else
        this.editableObject.polypaths[this.polytype][this.editableObject.polypaths[this.polytype].length - 1].push(event.latLng)

    },
    addPolygon() {
      console.log("addpolypoint" + this.polytype)
      // check its a valid polytype
      if (!this.polytype)//this.polytype = polytype
        return
      if(typeof this.editableObject.polypaths[this.polytype] === 'undefined')
        this.$set(this.editableObject.polypaths,this.polytype,[])
      if (this.editableObject.polypaths[this.polytype].length == 0 || this.editableObject.polypaths[this.polytype][this.editableObject.polypaths[this.polytype].length - 1].length >= 3)
        this.editableObject.polypaths[this.polytype].push([])
    },
    saveObject(force){

      if(this.statusfields.indexOf(this.editableObject.status)<0 && this.editableObject.draggable && force!==true) {
        this.savewarning = true;
      } else {
        this.loading = 'loading';
        this.savewarning = false;
        this.$store.dispatch('saveObject', this.editableObject).then((result) => (this.loading = result))
      }
      /*      {{ editableObject.lat }}
      {{ editableObject.draggable }}*/
    },
    startEventSource(url){
      let es = new EventSource(process.env.VUE_APP_BASE + url)


      es.addEventListener('progress', res=>{
        //console.log('PROCCESS')
//        console.log(res.data)
        console.log('progresshandler'+res.data)
        this.geolocateprogress = res.data
      })

      es.addEventListener('message', res=>{
        console.log('messagehandler'+res.data)

        this.infowindow = this.infowindow +'<br>'+ res.data
        //close connection when finished
        if(res.lastEventId == 'CLOSE')
          es.close()
        if(res.lastEventId == 'GEOLOCATE') {
          es.close()
          this.startEventSource('/importXLSX.php?geolocate=true',)
        }
      })

      es.addEventListener('error', ()=>{
//        es.close()
        this.infowindow = this.infowindow+'<br>'+'FEHLER aufgetreten'
      })
    },
    updateDB(e){
      this.infowindow ='.'
      //console.log(e)
      //console.log(process.env.VUE_APP_BASE)
      fetch(process.env.VUE_APP_BASE + '/importXLSX.php',{
        method: 'POST',
        body:new FormData(e.target)
      }).then(response => {
        if(!response.ok){
          throw Error(response.statusText)
        }
        return response.text()
      }).then(file=>{
        this.startEventSource('/importXLSX.php?process='+file)
      }).catch(error=>this.infowindow=error)
    },
  },
  mounted() {
    this.$store.dispatch('loadAllObjects').then(()=> {
      console.log('loadALL')
      if (this.role != 'admin') {
        this.selectedContractors = this.contractors
        this.$store.commit('showAllObjects')
        this.$store.dispatch('loadDoneObjects')
      }
        })
  },
  watch: {
    selectedContractors: {
      handler: function (newVal) {
        console.log('selectedConChanged')
        this.$store.commit('filterObjects', newVal)
      },
      deep: true
    }
  }
};
</script>


<style scoped>
.v-carousel__controls{
  position: relative;
}
.v-carousel{
  padding-bottom: 10px;
}
</style>

<style>
.v-navigation-drawer--temporary.v-navigation-drawer--clipped {
  z-index: 5;
  padding-top: 64px;
}

.editWindow{
  position: absolute;
  left: 0px;
}

.theme--light.v-btn--active:before, .theme--light.v-btn--active:hover::before,.theme--light.v-btn:hover::before {
  opacity: 0.5 !important;
}

.v-toolbar__title>button{
  margin: 0px 10px
}

.v-carousel__controls__item.v-btn{
  color:green !important;
}

.no_underlines .v-input__slot::before {
  border-style: none !important;
}

.v-carousel__controls{
  position: relative;
}
</style>