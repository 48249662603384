import Vue from 'vue'
import VueRouter from "vue-router";
import Login from "@/components/Login";
import UserProfiles from "@/components/UserProfiles";
import MainPage from "@/components/MainPage";


Vue.use(VueRouter)

const routes = [
    {path: '/', component: MainPage,
    children:[
        {path:'profiles', component: UserProfiles}
        ]},
    {path: '/login', component: Login},
    {path: '/register', component: Login},
    {path: "*" , redirect:"/login"}

]

const router = new VueRouter({
        routes
    }
)


export default router