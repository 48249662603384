<template>
  <router-view/>
</template>

<script>

export default {
  name: "App",
  methods:{
    checkLogin(){
      if (this.$store.state.userdata == null) {
        if (this.$route.path != '/login')
          this.$router.push('/login')
      } else {
        if (this.$store.state.userdata.role == 'admin') {
          this.$router.push('/')
        }
      }
    }
  },
  created () {
    document.documentElement.lang='de'
    document.documentElement.translate=false
  },
  mounted() {
    fetch( "/index.php/me",
        {
          credentials:'include'
        })
        .then(response => {
          if (!response.ok) {
            throw Error(response.status)
          }
          return response.json()
        }).then(response => {
      this.userdata = response
      this.$store.commit('setUserdata', response)
      this.checkLogin()
    }).catch(()=>{
      this.$store.commit('setUserdata', null);
      this.checkLogin()})
  }
}
</script>

<style>
</style>
