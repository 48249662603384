<template>
  <div>
    <Transition name="slide">
  <v-icon :color=color v-show="done">mdi-check-circle</v-icon>
    </Transition>
  <v-progress-circular v-show="loading"
                       indeterminate
                       :color=color
  ></v-progress-circular>

  </div>
</template>

<script>
export default {
  name: "loadingBtn",
  props: {
    status: {
      type: String,
      default: ''
    },
    timeout:{
      type: Number,
      default: 100
    },
    color:{
      type:String,
      default:"primary"
    }
  },
  data(){
    return {
      done: false,
      loading: false,
    }
  },
  watch: {
    status(accVal){
      if(accVal == 'loading')
        this.loading = true
      else{
        this.loading = false
        if(accVal == 'done') {
          this.done = true
          setTimeout(() => {
            this.done = false
          }, this.timeout)
        }
      }
    }
  }
}
</script>

<style scoped>

.slide-enter-active,
.slide-leave-active{
  transition: all 1s ease !important;
}

.slide-enter-from,
.slide-leave-to {
  transform:scale(5,5);
  opacity:0;
}
</style>