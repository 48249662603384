<template>
  <v-app>
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
              <v-toolbar dark color="green">
                <v-toolbar-title>WDControl Anmeldung</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <form ref="form" @submit.prevent="login" method="POST">
                  <v-text-field
                      name="username"
                      label="Username"
                      type="text"
                      placeholder="Username"
                      required
                  ></v-text-field>

                  <v-text-field
                      name="password"
                      label="Passwort"
                      type="password"
                      placeholder="Passwort"
                      required
                  ></v-text-field>

                  <div class="red--text">{{srvmsg}}</div>
                  <v-btn block type="submit" class="mt-4" color="primary" value="log in">Anmelden</v-btn>
                </form>
              </v-card-text>
            </v-card>

          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "Login",
  data:()=>({
    loginURL:"/index.php/login",
    srvmsg:null,
    userdata:null
  }),
  methods:{
    login(){
      let form = new FormData(this.$refs.form)

      //set username to lowercase for caseinsensivity
      form.set("username", form.get("username").toLowerCase())
      fetch(this.loginURL, {
        method: 'POST',
        body: form
      }).then(response => {
        if(!response.ok){
          throw Error(response.statusText)
        }
        return response.json()
      }).then(response=>{
          this.userdata = response
          this.$store.commit('setUserdata',response)
          this.$router.push('/')
      }).catch(error=>this.srvmsg=error)
    }
  }
}
</script>

<style scoped>

</style>