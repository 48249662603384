<template>
  <v-dialog
      v-model=dialog
      persistent
  >
    <v-card
        class="overflow-y:scroll d-flex flex-column"
        height="80vh"
    >
      <v-card-title>
        <span>Benutzer und Routen</span>
        <v-spacer></v-spacer>
        <v-btn
        icon
        @click="$emit('on',false)"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="text-center d-flex flex-column align-self-stretch overflow-hidden">
        <v-tabs
            v-model="tabcontrol"
            class="flex-grow-0"
            @change="error=false;srvMessage=''"
        >
          <v-tab>
            Routenverwaltung
          </v-tab>
          <v-tab>
            Benutzerverwaltung
          </v-tab>
          <v-tab>
            Benutzer anlegen
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabcontrol" class="d-flex flex-grow-1 pt-5">
          <v-tab-item class="flex-column overflow-hidden flexitem">
            <v-row class="flex-grow-0">
              <v-col>
                <h2>Benutzer</h2>
              </v-col>
              <v-col>
                <h2>Touren</h2>
              </v-col>
            </v-row>
            <v-row style='overflow-y:hidden;'>
              <v-col style="border-right:solid grey;overflow-y:scroll;height: 100%">
                <v-list>
                  <v-list-item-group
                      v-model="selectedUser"
                  >
                    <v-list-item v-for="(user,index) in users" :key="index">
                      {{user.username}}   -  {{user.tours.length}}
                    </v-list-item>

                  </v-list-item-group>
                </v-list>
              </v-col>
              <v-col>
                <v-select
                    v-model="usertours"
                    :items="tours"
                    item-text="name"
                    chips
                    label="Touren"
                    multiple
                ></v-select>
              </v-col>
            </v-row>
                <form ref="tourForm" @submit.prevent="saveTour()">
                  <span :class="[error ? 'error--text':'success--text',,'d-flex','justify-center','pt-10']">{{ srvMessage }}</span>
                  <v-btn class="align-self-center" type="submit" color="primary" value="saveroute">Speichern</v-btn>
                </form>
          </v-tab-item>
          <v-tab-item class="flex-column overflow-hidden flexitem">
            <v-row class="flex-grow-0">
              <v-col>
                <h2>Benutzer</h2>
              </v-col>
              <v-col>
                <h2>Benutzerdaten</h2>
              </v-col>
            </v-row>
            <v-row class="pb-10" style='overflow-y:hidden;'>
              <v-col style="border-right:solid grey;overflow-y:scroll;height: 100%">
                <v-list>
                  <v-list-item-group
                      v-model="selectedUser"
                  >
                    <v-list-item v-for="(user,index) in users" :key="index">
                      {{user.username}}   -  {{user.tours.length}}
                    </v-list-item>

                  </v-list-item-group>
                </v-list>
              </v-col>
              <v-col>
                <form ref="changeForm" @submit.prevent="saveUser()">
                  <v-text-field
                      disabled
                      v-model="user.username"
                      name="username"
                      label="Username"
                      type="text"
                      placeholder="Username"
                  ></v-text-field>

                  <v-text-field
                      v-model="user.password"
                      name="password"
                      label="Passwort"
                      type="password"
                      placeholder="Passwort"
                      :rules="passwordRules"
                      required
                      autocomplete = "off"
                  ></v-text-field>

                  <v-text-field
                      v-model="confirmPassword"
                      name="confirmPassword"
                      label="Passwort wiederholen"
                      type="password"
                      placeholder="Passwort wiederholen"
                      :rules="passwordRules"
                      required
                      autocomplete = "off"
                  ></v-text-field>
                  <v-select
                      v-model="user.role"
                      :items="[
                          {text:'Administrator',value:'admin'},
                          {text:'Fahrer',value:'driver'}
                          ]"
                      label="Rolle"
                  ></v-select>
                  <span :class="[error ? 'error--text':'success--text']"> {{ srvMessage }}</span>
                  <div class="d-flex flex-row justify-space-around">
                  <v-btn type="submit" class="mt-4" color="primary" value="save">Speichern</v-btn>
                  <v-btn type="button" class="mt-4" color="warning" value="delete"
                    @click="deleteUser()">
                      <v-icon>
                      mdi-delete-forever
                      </v-icon>
                    Löschen
                  </v-btn>
                  </div>
                  <div class="grey--text mt-4"></div>
                </form>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
          <form ref="registerForm" @submit.prevent="register()">
          <v-text-field
              v-model="username"
              name="username"
              label="Username"
              type="text"
              placeholder="Username"
              required
              autocomplete = "off"
          ></v-text-field>

          <v-text-field
              v-model="password"
              name="password"
              label="Passwort"
              type="password"
              placeholder="Passwort"
              :rules="passwordRules"
              required
              autocomplete = "off"
          ></v-text-field>

          <v-text-field
              v-model="confirmPassword"
              name="confirmPassword"
              label="Passwort wiederholen"
              type="password"
              placeholder="Passwort wiederholen"
              :rules="passwordRules"
              required
              autocomplete = "off"
          ></v-text-field>
          <span :class="[error ? 'error--text':'success--text','d-flex','justify-center']"> {{ srvMessage }}</span>
          <v-btn type="submit" color="primary" value="register">Speichern</v-btn>
          <div class="grey--text mt-4"></div>
        </form>
        </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "UserProfiles",
  props:{
    dialog: Boolean,
  },
  data: ()=>({
    tabcontrol:null,
    usertours:null,
    selectedUser:null,
    tours:null,
    users: null,
    username: "",
    password: "",
    confirmPassword: "",
    srvMessage:"",
    error: false,
    user:{id:null,
      username:null,
      role:null,
      password:null
    },passwordRules: [
      v => (v && v.length >= 8) || 'Passwort mindestens 8 Stellen'
    ]
  }),
  methods:{
    loadUsers:function(){
      fetch("/index.php/records/users?include=id,username,tours,role&order=username,asc").then(
          response => response.json())
          .then(data => {
            this.users = null
            this.users = data["records"]
            for(let user of this.users){
              user.tours = JSON.parse(user.tours)
            }
          }
          ).catch(error => console.error(error))
    },
    loadTours:function(){
      fetch("/index.php/records/tours").then(
          response => response.json())
          .then(data => {
                this.tours = data["records"]
              }
          ).catch(error => console.error(error))
    },
    register() {
      if(this.password == this.confirmPassword){
        this.srvMessage = "";
        this.error = false;
        let formdata = new FormData();
        formdata.append('username',this.username.toLowerCase());
        formdata.append('password',this.password);

        fetch("/index.php/register",
            {
              credentials:"include",
              method:"POST",
              body: formdata
            }).then(
            response => {
              if (!response.ok) {
                return response.json().then((msg) => {throw new Error(msg.message)})
              }
              return response.json()
            }).then(msg=>{
          this.srvMessage = msg.username +" anglegt"
          this.$refs.registerForm.reset();
          this.loadUsers()
        }).catch(error=> {
          this.srvMessage = error;
          this.error = true;
        })
      }
      else {
        this.error = true
        this.srvMessage = "Passwörter nicht identisch"
      }
    },
    saveUser() {
      if(this.user.password == this.confirmPassword) {
      console.log("saveTour")

      //let formdata = new FormData();
//      formdata.append('tours',JSON.stringify(this.usertours));
      let data = JSON.stringify(this.user)

      fetch( "/index.php/records/users/"+this.user["id"] ,
          {
            method:"PUT",
            header:{
              contentType: 'application/json'
            },
            body: data,
          }).then(
          response => {
            if (!response.ok) {
              return response.json().then((msg) => {throw new Error(msg.message)})
            }
            return response.json()
          }).then(()=>{
            this.error = false
        this.srvMessage = "gespeichert"
        this.$refs.changeForm.reset();
        this.loadUsers()
      }).catch(error=> {
        this.srvMessage = error;
        this.error = true;
      })
      }else {
        this.error = true
        this.srvMessage = "Passwörter nicht identisch"
      }
    },
    deleteUser() {
        //let formdata = new FormData();
//      formdata.append('tours',JSON.stringify(this.usertours));

        fetch( "/index.php/records/users/"+this.user["id"] ,
            {
              method:"DELETE",
              header:{
                contentType: 'application/json'
              }
            }).then(
            response => {
              if (!response.ok) {
                return response.json().then((msg) => {throw new Error(msg.message)})
              }
              return response.json()
            }).then(()=>{
          this.error = false
          this.srvMessage = "gelöscht"
          this.$refs.changeForm.reset();
          this.selectedUser = null;
          this.loadUsers()
        }).catch(error=> {
          this.srvMessage = error;
          this.error = true;
        })
    },
    saveTour() {
        //let formdata = new FormData();
//      formdata.append('tours',JSON.stringify(this.usertours));
        let data = JSON.stringify({tours: this.usertours})

        fetch("/index.php/records/users/" + this.users[this.selectedUser]["id"],
            {
              method: "PUT",
              header: {
                contentType: 'application/json'
              },
              body: data,
            }).then(
            response => {
              if (!response.ok) {
                return response.json().then((msg) => {
                  throw new Error(msg.message)
                })
              }
              return response.json()
            }).then(() => {
          this.error = false
          this.srvMessage = "gespeichert"
          this.$refs.tourForm.reset();
          this.loadUsers()
        }).catch(error => {
          this.srvMessage = error;
          this.error = true;
        })
    },
  },
  watch:{
    selectedUser(userid){
      if(userid!=null) {
        this.usertours = this.users[userid]["tours"]
        this.user.id = this.users[userid]["id"]
        this.user.username = this.users[userid]["username"]
        this.user.role = this.users[userid]["role"]
      }
    }
  },
  mounted(){
    console.log("userprofile loaded")
    this.loadUsers()
    this.loadTours()
  }
}
</script>

<style scoped>
  .v-window >>> .v-window__container{
    flex-grow: 1 !important;
    overflow:hidden;
}

  .flexitem{
    display: flex;
  }


</style>