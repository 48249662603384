export default{
    state:{
        editableObject:null,
        allWDObjects:[],
        matchingObjects:[],
        doneObjects:[],
        asd:null,
        activities: [
            'machine_clean',
            'machine_strow',
            'manual',
            'sidewalk',
            'status',
        ],
    },
    mutations:{
        setEditableObject(state,payload){
            let obj = payload.obj
            let draggable = payload.modifydraggable
            console.log("set editObj with:")
            console.log(obj)
            let setdraggable = false
            let foundObj = false

            //set draggable if its already the editable object, so we need 4 clicks total
            if(state.editableObject != null && state.editableObject.ID == obj.ID && draggable)
                setdraggable = true

            //set editableObject
            state.editableObject=obj
            console.log('set editable marker '+obj.ID)

            //Object.keys(obj).forEach(key=>Vue.set(state.editableObject,key,obj[key]))

            state.matchingObjects.forEach((wdObj,index)=>{
                if(wdObj.ID==obj.ID && setdraggable) {
                    wdObj.draggable = true
                    foundObj = true
                    console.log("found draggable with index:"+index)
                }
                else
                    wdObj.draggable = false
            })

            if(obj!==false && !foundObj && state.matchingObjects.length <=1) {
                state.matchingObjects = [obj]
            }
        },
        setObjectDone(state,payload){

            console.log("set OBJ done:")
            console.log(payload)

            if(payload.save == true) {
                delete payload.save
                //send to DB
                fetch('/index.php/records/worklog/',
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(payload)
                    }).then(response => {
                    if (!response.ok) {
                        throw Error(response.statusText)
                    }
                    return true
                }).catch(error => this.srvmsg = error)
            }
            /*

            let index = state.doneObjects.findIndex((obj) => obj.ID == payload.objID && obj.tour == payload.tour)

          if(index < 0) {
                state.doneObjects.push(payload)
            }
            else {
                state.doneObjects[index] = payload
            }
*/
            let alldone = true
            state.matchingObjects.forEach(obj => {
                if(obj.oid == payload.objID && obj[obj.type]==payload.tour) {
                    if (payload.timestamp > (Date.now() - 1 * 60 * 60 * 1000))
                        obj.type = 'done';
                    else if (payload.timestamp > (Date.now() - 12 * 60 * 60 * 1000))
                        obj.type = 'alert';
                }
                if(obj.type!='alert' && obj.type!='done')
                    alldone = false
            })

            if(alldone) {
                alert('SUPA OLLES FERTIG')
                this.commit('showAllObjects')
            }
        },
        updateEditableObject(state,obj){
            console.log('Update editable marker '+obj.ID)

            //set draggable if its already the editable object, so we need 4 clicks total
            if(state.editableObject != null && state.editableObject.ID == obj.ID && state.editableObject.draggable)
                state.editableObject=obj
            //set editableObject
        },
        setAllObjects(state,allObj){
            console.log("setAllObjects")
            state.allWDObjects = allObj
        },
        filterObjects:(state,selectedContractors)=>{
            let objectlist = [];
            console.log('calc matching obj')

            state.allWDObjects.forEach( actualObject => {
                Object.keys(selectedContractors).forEach(
                    type => {
                        if (selectedContractors[type].indexOf(actualObject[type]) != -1) {
                            actualObject.type = type;
                            objectlist.push(Object.assign({},actualObject));
                        }
                    })
            })
            console.log(objectlist)
            state.matchingObjects = objectlist
        },
        showAllObjects:(state)=> {
            console.log('showAll')
            state.matchingObjects = state.allWDObjects
        }
    },
    actions:{
        loadAllObjects:function(context){
            return fetch("/index.php/records/objects_contacts").then(
                response => response.json()).then(data =>
                {
                    data=data["records"]
                    for(let element of data)
                    {
                        element.polypaths = JSON.parse(element.polypaths)
                    }

                    //this.wdObjects_all = data
                    context.commit('setAllObjects',data)
                    //this.wdObjects_all.map(obj=>obj.draggable=true)
                }
            ).catch(error => console.error(error))
        },
        loadDoneObjects:function(context){
            return fetch("/index.php/records/donelast12h").then(
                response => response.json()).then(data =>
                {
                    data=data["records"]

                    data.forEach(
                        element => context.commit('setObjectDone',element)
                    )
                }
            ).catch(error => console.error(error))
        },
        saveObject: async function(context,objToSave){
            let data = JSON.stringify(objToSave)

            let url = process.env.VUE_APP_BASE + "/index.php/records/objekte/"
            url +=!objToSave.ID?'':objToSave.ID

            let action = !objToSave.oid?'POST':'PUT'

            objToSave.lat = toString(parseFloat(objToSave.lat))
            objToSave.lng = toString(parseFloat(objToSave.lng))

            return fetch(url,
                {
                    method:action,
                    headers:{
                        'Content-Type':'application/json',
                    },
                    body: data,
                }).then(response => {
                console.log(response)
                console.log('LOADED IN FETCH')
                if(response.ok){
                    console.log("respone ok on saveObjects:"+response.ok)
                    response.json().then(data=>console.log(data)).catch(error=>console.log(error))

                    context.dispatch('loadAllObjects')

                    //update local wdobjects
//                    index = this.wdObjects_all.findIndex(currentObj => currentObj.ID==this.editableObject.ID)
//                    this.wdObjects_all[index] = this.editableObject

                    //reset draggable and editwindow
//                    this.mod(0)
//                    this.editableObject = null
                    return 'done'
                }
                else {
                    alert('FEHLER BEIM SPEICHERN: ' + response.status)
                    throw new Error(response.statusText)
                }
            }).catch(error=>{console.error('FEHLER BEIM SPEICHERN: '+ error); return 'failed'})
        }
    },
    getters:{
        /**
         *  walk through every object and extract uniq contractors
         *  "-" is for empty entries
        * */
        all_contractors:state =>{
            let contractorlist = {};

            //generate arrays for the list
            state.activities.forEach(type => contractorlist[type]=[])

            state.allWDObjects.forEach(item=> {
                state.activities.forEach(type => {
                    if(contractorlist[type].findIndex( e => e==item[type]) == -1 && item[type] != "-" && !!item[type])
                        contractorlist[type].push(item[type])
                })
                /*        if(contractorlist.machine_clean.findIndex( e => e==item[this.translate[]]) == -1)
                          contractorlist.machine_clean.push(item["maschinelle räumung"])
                        if(contractorlist.machine_strow.findIndex( e => e==item["maschinelle streuung"]) == -1)
                          contractorlist.machine_strow.push(item["maschinelle streuung"])
                        if(contractorlist.manual.findIndex( e => e==item["händische räumung_streuung"]) == -1)
                          contractorlist.manual.push(item["händische räumung_streuung"])
                        if(contractorlist.sidewalk.findIndex( e => e==item["gehsteig"]) == -1)
                          contractorlist.sidewalk.push(item["gehsteig"])*/
            })
            Object.keys(contractorlist).forEach(function(type) {
                contractorlist[type].sort()
            })
            return contractorlist
        },
        all_contractors_in_tours:state =>{
            let contractorlist = {};

            //generate arrays for the list
            contractorlist['tour']=[]

            state.allWDObjects.forEach(item=> {
                if(contractorlist['tour'].findIndex( e => e==item['tour']) == -1 && item['tour'] != "-" && !!item['tour'])
                    contractorlist['tour'].push(item['tour'])
            })
                contractorlist['tour'].sort()

            return contractorlist
        },
        getObjectById: (state) => (ID) => {
            return state.allWDObjects.find(object => object.ID === ID);
        },/*
        matchingObjects:(state)=>(selectedContractors)=>{
            let objectlist = [];
            console.log('calc matching obj')

            state.allWDObjects.forEach( checkObject => {
                Object.keys(selectedContractors).forEach(
                    type => {
                        if (selectedContractors[type].indexOf(checkObject[type]) != -1) {
                            checkObject.type = type;
                            objectlist.push(Object.assign({},checkObject));
                        }
                    })
            })

            this.commit('')
            return objectlist
        },*/
        editObj:state => state.asd
    }
}